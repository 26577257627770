<template>
	<!-- 登录后选择客户弹窗 -->
	<div class="payloaging elselectcustno">
		<el-dialog title="请选择客户" :visible.sync="showcustomer" :show-close="false" :close-on-click-modal="false">
			<div class="relative">
				<el-select v-model="erpcustno" placeholder="请选择客户名称" @change="clearable" clearable filterable remote :remote-method="getcustomer">
				   <el-option v-for="(item,k) in querymycustomerlist"  :key="k" :label="item.companyname" :value="item.erpcustno"> </el-option>
				 </el-select>
			</div>
			<div class="flex f-14 s-c tc absolute b-50  w100 l-0 ">
				<p class="w-100 rad-2 h-34 l-h-34 bg-00B c-f cursor" @click="selectCustomers">确定</p>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		props:['showcustomer'],
		data(){
			return{
				erpcustno:'',
				querymycustomerlist:[],//客户列表
				query:'',//搜索
				time:''
			}
		},
		methods:{
			
			// 选择客户
			selectCustomers(){
				if (!this.erpcustno) return this.$notify({title: '错误',message: '当前客户erp编码不存在',type: 'error'});;
				sessionStorage.setItem('erpcustno', this.erpcustno);
				for (var i in this.querymycustomerlist) {
					if (this.querymycustomerlist[i].erpcustno == this.erpcustno) {
						this.companyname = this.querymycustomerlist[i].companyname;
						sessionStorage.setItem('companyname', this.querymycustomerlist[i].companyname);
						sessionStorage.setItem('companycode', this.querymycustomerlist[i].companycode);
						sessionStorage.setItem('erpcustno', this.querymycustomerlist[i].erpcustno);
						sessionStorage.setItem('companytype', this.querymycustomerlist[i].companytype);
						
						this.chageerpcustno = false;
						this.$notify({title: '成功',message: '登录成功',type: 'success'});
						setTimeout(()=>{
							console.log(this.$route.path)
							if(this.$route.path=='/index'){this.$router.go(0)}else{
								this.$router.push({path:'/index'})
							}
						}, 100);
					}else{
						
						
					}
				}
			},
			
			// 切换分公司
			handleCommand(erpcustno) {
				var erpcustno = sessionStorage.getItem('erpcustno');
				sessionStorage.setItem('erpcustno', erpcustno);
				for (var i in this.querymycustomerlist) {
					if (this.querymycustomerlist[i].erpcustno == erpcustno) {
						this.companyname = this.querymycustomerlist[i].companyname;
						sessionStorage.setItem('companyname', this.querymycustomerlist[i].companyname);
						this.$notify({title: '成功',message: '切换成功',type: 'success'});
						setTimeout(function() {
							location.reload();
						}, 1000);
					}
				}
			},
			
			
			
			getcustomer(value){
				this.query=value
				clearTimeout(this.time)
				this.time=setTimeout(()=>{this.querymycustomer()},1000)
	
				
			},
			clearable(){
				this.query=''
				this.querymycustomer()
			},
			// 获取业务员客户
			querymycustomer() {
	
				var salesmannum = sessionStorage.getItem('salesmannum');
				var url = '/b2bcompanyinfo/querymycustomer';
				var data = {salesmannum: salesmannum,query:this.query};
				var Storagequerymycustomer = sessionStorage.getItem('querymycustomer');

				if (Storagequerymycustomer) {
					this.querymycustomerlist = JSON.parse(Storagequerymycustomer);
					// return;
				}

				this.requset(url, data).then(res=> {
					console.log(res)
					var res
					if (res.data.length) {
						this.chageerpcustno = true;
						this.querymycustomerlist = res.data;
						sessionStorage.setItem('querymycustomer', JSON.stringify(res.data));
					} else {
						this.$notify.error({title: '提示',message: '没有找到客户'});
						this.chageerpcustno = false;
						// setTimeout(function() {window.logout();}, 2000);
					}
				}).catch(res=>{
					this.$notify.error({title: '错误',message: res.data.message});
				});
			
			},
			
			
		}
	}
	
</script>

<style>
	
	.el-dialog__title{color: #FFFFFF;}
</style>

